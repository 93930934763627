export const staticRouteNames = {
  ActivationsGrants: 'Activations Grants/activations-grants',
  Home: 'static/home',
  About: 'static/about',
  OperatorsLanding: 'static/operators-landing',
  CancellationPolicy: 'static/cancellation-policy',
  Privacy: 'static/privacy',
  Terms: 'static/terms',
  NotFound: 'static/not-found'
}
